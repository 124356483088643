import Icon from '@ant-design/icons'

const UploadSvg = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M3.79595 13.7963C2.17291 13.7963 0.857178 12.6894 0.857178 11.0663C0.857178 9.44328 2.17291 8.12755 3.79595 8.12755C3.90632 8.12755 4.01527 8.13364 4.12248 8.14549V8.12755H4.1629C4.13623 7.91361 4.12248 7.69565 4.12248 7.47449C4.12248 4.58908 6.46157 2.25 9.34697 2.25C11.3009 2.25 13.0043 3.32261 13.9005 4.91114C14.1195 4.87893 14.3435 4.86224 14.5715 4.86224C17.0962 4.86224 19.1429 6.90894 19.1429 9.43367C19.1429 11.512 17.756 13.0963 15.8572 13.6186M9.85575 17.75V10.25M9.85575 10.25L6.85718 13.3431M9.85575 10.25L12.8572 13.3431"
      stroke="#16181D"
      strokeWidth="1.4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

const UploadIcon = (props: any) => <Icon component={UploadSvg} {...props} />

export default UploadIcon
