import { NotesDocType } from './types'

export const updateRoomNote = (docs: NotesDocType[], notes: NotesDocType[]): NotesDocType[] => {
  notes.forEach((note) => {
    const index = docs.findIndex(({ id }) => id === note.id)
    if (index !== -1) docs[index] = note
  })
  return docs
}

export const deleteRoomNote = (
  notes: NotesDocType[],
  id: string | string[],
): { notes: NotesDocType[]; lastId?: string | null } => {
  const index = notes.findIndex((note) =>
    Array.isArray(id) ? id.includes(note.id) : note.id === id,
  )
  if (index !== -1) notes.splice(index, 1)

  if (index === 0 && notes[0]) {
    return { notes, lastId: notes?.[0].id ?? null }
  }
  return { notes }
}

export const addRoomNote = (docs: NotesDocType[], notes: NotesDocType[]): NotesDocType[] => {
  if (!docs.length) return []
  notes.forEach((note) => {
    const index = docs.findIndex(({ id }) => id === note.id)
    if (index === -1) docs.push(note)
  })
  return docs.sort((a, b) => Date.parse(a.time) - Date.parse(b.time))
}
