import * as action_types from './constants'

const initialState = {
  checklisting: [],
  checklistData: [],
  checklistItemData: [],
}

export default (state = initialState, action: any) => {
  const { type, data } = action
  switch (type) {
    case action_types.CHECKLIST_LISTING:
      return { ...state, checklisting: data }

    case action_types.CHECKLIST_DATA:
      return { ...state, checklistData: data }

    case action_types.CHECKLISTITEM_DATA:
      return { ...state, checklistItemData: data }

    default:
      return state
  }
}
