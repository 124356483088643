import * as action_types from './constants'

const initialState = {
  projectListData: [],
  projectTaskList: [],
  projectsData: [],
  filtersData: [],
  notesData: [],
  filterProject: {
    assignees: undefined,
    departments: undefined,
    types: undefined,
    locations: undefined,
    project_id: undefined,
  },
}

export default (state = initialState, action: any) => {
  const { type, data } = action
  switch (type) {
    case action_types.PROJECTS_LIST:
      return { ...state, projectListData: data }

    case action_types.PROJECTS_DATA:
      return { ...state, projectsData: data }

    case action_types.FILTER_DATA:
      return { ...state, filtersData: data }

    case action_types.PROJECTS_TASK_LIST:
      return { ...state, projectTaskList: data }

    case action_types.SET_FILTERS_OPTIONS_PROJECTS:
      return {
        ...state,
        filterProject: { ...state.filterProject, ...action.payload.filterProject },
      }
    case action_types.GET_ROOM_NOTES:
      return { ...state, notesData: data }

    default:
      return state
  }
}
