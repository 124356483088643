import { CleaningResultType, GuestType } from '@/redux/room/types'
import { Statuses } from '@/config/constants'
import { roomsLayoutForSocket } from '@/utils/helpers'
import * as action_types from './constants'
import { GroupedNotesType, RoomListForSocket } from './types'

export interface RoomState {
  roomsFilter: {
    floors: { value: string; label: number }[]
    rooms: { value: string; label: number }[]
    reservations: string[]
    cleaningStatus: string[]
    turndownStatus: string[]
  }
  roomCardListing: {
    roomList: {
      [key: string]: {
        id: string
        type: string
        room_number: number
        cleaningStatus?: string
        turndownStatus?: string
        reservation_status?: string
        linenStatus?: string
        cleaning_attendant?: string
        turndown_attendant?: string
        pref_cleaning_time?: string | null
        pref_turndown_time?: string
        is_cleaning?: boolean
        is_turndown?: boolean
        uploading: boolean
      }[]
    }
    floorOrder: string[]
    attendants: {
      id: string
      name: string
      initials: string
      image?: string
    }[]
    loading: boolean
  }
  roomCardDetail: {
    cleaning: CleaningResultType
    turndown: CleaningResultType
    cleaningStatus: keyof typeof Statuses
    id: string
    turndownStatus: string
    room_number: string
    guest: GuestType[]
    linenStatus: string
    reservation_status: string
    reservationStatus: string
    loading: boolean
    section_name: string
    type: string
    is_cleaning?: boolean
    is_turndown?: boolean
    pinnedNotes?: {
      entityId: string
      id: string
      isDeleted: boolean
      isPinned: boolean
      moduleName: string
      note: string
      time: string
      userId: string
    }
  }
  getNotes: {
    loading: boolean
    notes: GroupedNotesType[]
  }
  filter: {
    floor: string[]
    areBeingCleaned: boolean
    rooms: string[]
    attendant: string[]
    reservation_status: string[]
    status: string[]
    assignees: undefined | string
    departments: undefined | string
    types: undefined | string
    locations: undefined | string
    start_time: undefined | string
  }
  reverseRoomLayout: RoomListForSocket
}

const initialState = {
  roomsFilter: {
    floors: [],
    rooms: [],
    reservations: [],
    cleaningStatus: [],
    turndownStatus: [],
  },
  roomCardListing: {
    roomList: {
      uploading: false,
    },
    attendants: {},
    loading: false,
  },
  getNotes: {
    loading: false,
    notes: [],
  },
  roomCardDetail: {
    loading: false,
  },
  filter: {
    assignees: undefined,
    departments: undefined,
    types: undefined,
    locations: undefined,
    start_time: undefined,
  },
  reverseRoomLayout: null,
}

const updateStateRoomSidebar = (state: any, data: any) => {
  const newRoomStatus: any = {}
  if (data?.cleaning_time) {
    newRoomStatus.guest = [...state.guest]
    if (newRoomStatus.guest.length > 0) {
      newRoomStatus.guest[0] = {
        ...newRoomStatus.guest[0],
        cleaning_time: data?.cleaning_time,
        turndown_time: data?.turndown_time,
      }
    }
    newRoomStatus.loading = false
  }

  return {
    roomCardDetail: { ...state, ...newRoomStatus },
  }
}

export default (state = initialState, action: any) => {
  const { type, data } = action
  switch (type) {
    case action_types.SET_STATE:
      return {
        ...state,
        roomCardDetail: { ...state.roomCardDetail, loading: action.payload.loading },
      }

    case action_types.LIST_SET_STATE:
      return {
        ...state,
        roomCardListing: { ...state.roomCardListing, loading: action.payload.loading },
      }

    case action_types.NOTES_SET_STATE:
      return {
        ...state,
        getNotes: { notes: [], loading: action.payload.loading },
      }

    case action_types.ROOM_FILTER:
      return { ...state, roomsFilter: data }

    case action_types.ROOM_LISTING:
      return { ...state, roomCardListing: data }

    case action_types.GET_NOTES:
      return { ...state, getNotes: { notes: data, loading: false } }

    case action_types.ROOM_DETAIL:
      return { ...state, roomCardDetail: data }

    case action_types.UPDATE_STATE_SIDEBAR:
      return updateStateRoomSidebar(state.roomCardDetail, action.payload)

    case action_types.SET_FILTERS_OPTIONS:
      return { ...state, filter: { ...state.filter, ...action.payload.filter } }

    case action_types.ROOMS_LAYOUT_SOCKET:
      return { ...state, reverseRoomLayout: roomsLayoutForSocket(data) }
    default:
      return state
  }
}
