import Icon from '@ant-design/icons'

const PlusSvg = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="31" height="30" viewBox="0 0 31 30" fill="none">
    <path
      d="M21.0569 14.9553H9.77681"
      stroke="#7F8385"
      strokeWidth="1.29614"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15.4169 20.5955V9.31535"
      stroke="#7F8385"
      strokeWidth="1.29614"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    {/* <rect
      x="0.960003"
      y="0.498516"
      width="29.003"
      height="29.003"
      rx="14.5015"
      stroke="#B9BBBD"
      strokeOpacity="0.22"
      strokeWidth="0.997032"
    /> */}
  </svg>
)

const PlusIcon = (props: any) => <Icon component={PlusSvg} {...props} />

export default PlusIcon
