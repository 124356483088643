import Icon from '@ant-design/icons'

const DeleteIconNewSvg = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.7172 2.68218C6.7172 2.29558 7.0306 1.98218 7.4172 1.98218H12.5828C12.9694 1.98218 13.2828 2.29558 13.2828 2.68218C13.2828 3.06878 12.9694 3.38218 12.5828 3.38218H7.4172C7.0306 3.38218 6.7172 3.06878 6.7172 2.68218ZM2.41254 5.26498C2.41254 4.87838 2.72594 4.56498 3.11254 4.56498H16.8875C17.2741 4.56498 17.5875 4.87838 17.5875 5.26498C17.5875 5.65158 17.2741 5.96498 16.8875 5.96498H15.8209C15.8285 6.03893 15.8308 6.11436 15.8276 6.19089L15.4351 15.612C15.4266 16.9423 14.3456 18.018 13.0133 18.018H6.98674C5.65444 18.018 4.57341 16.9423 4.56492 15.612L4.17238 6.19089C4.16919 6.11436 4.17155 6.03893 4.17911 5.96498H3.11254C2.72594 5.96498 2.41254 5.65158 2.41254 5.26498ZM5.73196 5.96498C5.64045 5.96498 5.56735 6.04118 5.57116 6.13261L5.96487 15.5816V15.5962C5.96487 16.1605 6.42237 16.618 6.98674 16.618H13.0133C13.5776 16.618 14.0351 16.1605 14.0351 15.5962V15.5816L14.4288 6.13261C14.4327 6.04118 14.3596 5.96498 14.268 5.96498H5.73196Z"
    />
  </svg>
)

const DeleteIconNew = (props: any) => <Icon component={DeleteIconNewSvg} {...props} />

export default DeleteIconNew
