import Icon from '@ant-design/icons'

const PlusSvg = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10 3.75C10.4393 3.75 10.7955 4.10614 10.7955 4.54545L10.7955 9.20455H15.4545C15.8939 9.20455 16.25 9.56068 16.25 10C16.25 10.4393 15.8939 10.7955 15.4545 10.7955H10.7955L10.7955 15.4545C10.7955 15.8939 10.4393 16.25 10 16.25C9.56068 16.25 9.20455 15.8939 9.20455 15.4545L9.20455 10.7955H4.54545C4.10614 10.7955 3.75 10.4393 3.75 10C3.75 9.56068 4.10614 9.20455 4.54545 9.20455H9.20455L9.20455 4.54545C9.20455 4.10614 9.56068 3.75 10 3.75Z"
      fill="white"
    />
  </svg>
)

const PlusIconNew = (props: any) => <Icon component={PlusSvg} {...props} />

export default PlusIconNew
