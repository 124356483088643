import React, { Fragment } from 'react'
import * as action_types from './constants'

export interface RoleStateType {
  hotelRolePermission: {
    templates: {
      department_name: string
      role_name: string
      color: string
    }[]
    permissions: {
      [key: string]: {
        title: string
        description: any
        template_permissions: {
          id: string
          role_name: string
          is_changeable: boolean
          access_level: number
          is_checked: boolean
          show_access_selector: boolean
          is_disabled: boolean
        }[]
      }[]
    }
  }
}

const initialState: RoleStateType = {
  hotelRolePermission: {
    templates: [],
    permissions: {},
  },
}

export default (state = initialState, action: any) => {
  const { type, data } = action
  switch (type) {
    case action_types.HOTEL_ROLE_PERMISSION:
      return { ...state, hotelRolePermission: data }

    default:
      return state
  }
}
