/* eslint-disable @typescript-eslint/naming-convention */
import { RoomType } from '@/redux/rooms/types'
import {
  allocateNormalize,
  basketNormalize,
  initAllocatedNormalize,
} from '@/redux/turndownAllocations/service'
import { IRootState } from '../reducers'

export const getBasket = (state: IRootState): Record<string, string[]> => {
  const { floor, status, reservation_status } = state.turndownAllocations.filters
  const basket = state.turndownAllocations.turndownAllocated?.basket ?? []

  const filtered = Object.values(state.turndownAllocations.rooms)

    .filter((room: RoomType) => {
      return (
        (floor && floor?.length > 0 ? floor.find((e: any) => room.floor === e) : true) &&
        (status && status?.length > 0 ? status.find((e: any) => room.status === e) : true) &&
        (reservation_status && reservation_status?.length > 0
          ? reservation_status.find((e: any) => room.reservation_status === e)
          : true)
      )
    })
    .reduce((acc: string[], room) => [...acc, room.id], [])
  return {
    basket: basket.filter((item: any) => filtered.includes(item)),
  }
}

export const getInitAllocatedState = (state: IRootState): Record<string, string[]> => {
  const initAllocated = initAllocatedNormalize(state.turndownAllocations.rooms)
  const turndownAllocated = allocateNormalize(
    state.turndownAllocations.turndownAttendants,
    initAllocated,
  )

  turndownAllocated.basket = basketNormalize(state.turndownAllocations.rooms)
  return turndownAllocated
}

export const isChanged = (state: IRootState): boolean => {
  return Object.keys(state.turndownAllocations.history).length > 0
}
