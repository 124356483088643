import React, { Fragment, useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Redirect, useHistory } from 'react-router-dom'
import LoadingSpinner from '@/components/Spinners/LoadingSpinner'
import { Layout, Image, Button, Avatar, message, Tooltip } from 'antd'
import locationArrow from '@/assets/svg/location-arrow.svg'
import logoIcon from '@/assets/img/logo-icon.svg'
import leftArrow from '@/assets/svg/admin/white-left-arrow.svg'
import logoText from '@/assets/img/logo-text.svg'
import plusIcon from '@/assets/svg/plus-activation.svg'
import navigations from '@/config/navigations'
import LocationSidebar from '@/views/AdminLocations/Sidebar'
import { AxiosResponse } from 'axios'
import { useAppSelector } from '@/redux/store'
import { getUserDetails, isLoggedIn, loading } from '@/views/Login/ducks/actions'
import { useDispatch } from 'react-redux'
import { deActiveLocation } from '@/views/Settings/ducks/services'
import { Success } from '@/components/IconsCustom'
import { revertChanges } from '@/redux/allocations/actions'
import Navigation from './components/Navigation'
import ResponsiveNavigation from './components/ResponsiveNavigation'
import { LayoutProps } from '../types'
import { switchLocation } from './Services'

const { Sider } = Layout

const useStyles = makeStyles(() => ({
  layout: {
    display: 'flex',
    minHeight: '100vh',
  },
  page: {
    display: 'flex',
    flexGrow: 1,
    // paddingLeft: '70px',
    maxWidth: '100%',
  },
}))

const SidebarLayout: React.FunctionComponent<LayoutProps> = ({
  children,
  match,
  location,
}: LayoutProps) => {
  const classes = useStyles()
  // const { authenticated, isPending, user } = useAuth()
  const user = useAppSelector((state) => state.user.user)
  const statMenu: boolean = JSON.parse(localStorage?.getItem('collapsable') ?? 'false')
  const [collapsed, setCollapsed] = useState<boolean>(statMenu)
  const [isLocationMenu, setIsLocationMenu] = useState<boolean>(false)
  const [loader, setLoading] = useState<boolean>(false)
  const locationData: any = user?.available_locations
  const authState = useAppSelector((state) => state?.authentication)

  const route = navigations.find((item) => location.pathname?.includes(item.name))
  const appUser = useAppSelector((state) => state?.user?.user)
  const history = useHistory()
  const dispatch = useDispatch()
  const auth = isLoggedIn()

  useEffect(() => {
    if (statMenu) {
      setCollapsed(statMenu)
    }
  }, [statMenu])

  if (!user && !auth) {
    return <Redirect to="/auth" />
  }

  if (appUser) {
    const permissionRoute = user?.permission?.find((item) => item?.name === route?.permissionName)
    const checkedPermissionRoute = user?.permission?.find((item) => item?.is_checked === true)

    if (permissionRoute && !permissionRoute?.is_checked) {
      const currentRouteIndex = navigations.findIndex((item) => item.name === route?.name)

      if (currentRouteIndex !== -1) {
        return (
          <Redirect
            to={`${
              navigations[currentRouteIndex + 1] ? navigations[currentRouteIndex + 1].name : '/'
            }`}
          />
        )
      }
      return <Redirect to="/" />
    }

    if (appUser?.role !== 'Admin' && location.pathname.includes('admin')) {
      return <Redirect to="/" />
    }

    if (appUser?.role === 'Admin' && location.pathname.includes('manager')) {
      return <Redirect to="/" />
    }

    // if (appUser?.role === 'Admin' && !location.pathname.includes('admin')) {
    //   return <Redirect to="admin/users" path="/admin/users" />
    //   // console.log(location)
    // }
    if (appUser?.role === 'location_admin' && location.pathname.includes('admin')) {
      return <Redirect to="/admin/users" />
      // console.log(location)
    }
    // if (!appUser?.role?.toLowerCase().includes('admin') && appUser?.permission.length === 0) {
    //   return <Redirect to="/dashboard" />
    // }
  }

  // if (!user && !auth) {
  //   return <LoadingSpinner />
  // }

  if (authState?.auth.loading) {
    return <LoadingSpinner />
  }

  const refreshPage = async () => {
    await dispatch(getUserDetails())
    setIsLocationMenu(false)
    dispatch(revertChanges())
    localStorage.removeItem('roleValue')
    localStorage.removeItem('shiftValue')
    localStorage.setItem('isChange', 'false')
    dispatch(loading(false))
    history.push('/dashboard')
  }

  const changeLocation = (hotelId: string) => {
    dispatch(loading(true))
    switchLocation(hotelId)
      .then((res: AxiosResponse<{ success: boolean; message: string }>) => {
        if (!res.data.success) {
          return history.push('/noAccess')
        }
        // dispatch(getUserDetails())
        return refreshPage()
      })
      .catch(() => {})
  }

  const activateLocation = () => {
    setLoading(true)
    const hotelID = appUser?.hotel?.id as string
    deActiveLocation(hotelID, 'Active')
      .then(() => {
        message.success({
          content: 'Location activated',
          icon: <Success />,
        })
        setLoading(false)
        window.location.reload()
      })
      .catch(() => {
        setLoading(false)
      })
  }

  return (
    <Layout className={collapsed ? 'small_sidebar' : 'big_sidebar'}>
      <Sider
        trigger={null}
        collapsible
        collapsed={collapsed}
        width={244}
        collapsedWidth={80}
        className="nav_bar_dashboard"
      >
        {isLocationMenu ? (
          <div className="location_sidebar_menu">
            <Button onClick={() => setIsLocationMenu(false)} type="link">
              <Image src={locationArrow} preview={false} />
            </Button>
            <h3>Select Location</h3>
            <div className="location_ul">
              {locationData &&
                locationData?.length > 0 &&
                locationData?.map((resp: { label: string; value: string; address: string }) => (
                  <Fragment key={resp?.value}>
                    <Button
                      disabled={user?.hotel?.id === resp?.value}
                      className={
                        user?.hotel?.id === resp?.value
                          ? 'loc_change_btn active_location'
                          : 'loc_change_btn'
                      }
                      onClick={() => changeLocation(resp?.value)}
                      type="link"
                    >
                      <div className="location_label">{resp?.label}</div>
                      <div className="location_address">{resp?.address}</div>
                    </Button>
                  </Fragment>
                ))}
            </div>
          </div>
        ) : (
          <>
            <div className="sidebar_logo">
              <Image preview={false} src={logoIcon} width={28} />
              {!collapsed && <Image preview={false} src={logoText} width={77} />}
            </div>
            {user?.hotel?.name &&
              !window.location.pathname.includes('admin') &&
              !window.location.pathname.includes('manager') && (
                <div className="sidebar_location">
                  <div className="location_top">
                    {user && user?.hotel?.image ? (
                      <Avatar size={40} src={user?.hotel?.image} />
                    ) : (
                      <Avatar size={40} className="initials_avatar_big">
                        {user?.hotel?.initials}
                      </Avatar>
                    )}
                    {!collapsed && (
                      <div className="location_name_btn">
                        <Tooltip title={user?.hotel?.name} overlayClassName="hotel_tooltip">
                          <div className="location_label">{user?.hotel?.name}</div>
                        </Tooltip>
                        {locationData && locationData?.length > 1 && (
                          <div className="change_location_btn">
                            <Button type="link" onClick={() => setIsLocationMenu(true)}>
                              <Image src={locationArrow} preview={false} />
                            </Button>
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                  {user?.hotel?.address && !collapsed && (
                    <div className="location_info">{user?.hotel?.address}</div>
                  )}
                </div>
              )}
            {!window.location.pathname.includes('admin') &&
              !window.location.pathname.includes('manager') &&
              user?.stack !== 'staff' &&
              user?.stack !== 'attendant' && (
                <div className="back_to_home">
                  <Button
                    onClick={() =>
                      user?.role === 'Admin'
                        ? history.push('/admin/locations')
                        : history.push('/manager/locations')
                    }
                    className="no_bg back_location"
                    style={{ fontSize: 14 }}
                  >
                    <img src={leftArrow} alt="Plus" style={{ marginRight: '8px' }} />{' '}
                    {!collapsed && 'Homepage'}
                  </Button>
                </div>
              )}

            {user?.role !== 'Admin' && window.location.pathname.includes('manager') ? (
              <LocationSidebar
                navType="location"
                collapsed={collapsed}
                setCollapsed={setCollapsed}
              />
            ) : collapsed ? (
              <ResponsiveNavigation
                user={user}
                activePath={match.path}
                setCollapsed={setCollapsed}
                collapsed={collapsed}
              />
            ) : (
              <Navigation
                user={user}
                activePath={match.path}
                setCollapsed={setCollapsed}
                collapsed={collapsed}
              />
            )}
          </>
        )}
      </Sider>
      <Layout className="content_section">
        {appUser?.hotel?.status?.toLowerCase() === 'deactivated' &&
          !location.pathname.includes('admin') &&
          !location.pathname.includes('manager') && (
            <div className="deactivatedHeader">
              <div className="info_text">This location is not active</div>
              <Button className="activate_btn" onClick={activateLocation}>
                <img src={plusIcon} alt="Plus" width="20" /> Activate
              </Button>
            </div>
          )}

        <div
          className={`${classes.page}  ${
            appUser?.hotel?.status?.toLowerCase() === 'deactivated' &&
            !location.pathname.includes('admin') &&
            !location.pathname.includes('manager') &&
            'deactivedContent'
          }`}
        >
          {children}
        </div>
      </Layout>
    </Layout>
  )
}

export default SidebarLayout
