import * as action_types from './constants'

export interface RoleStateType {
  requestItems: []
  deliveryServiceListData: []
  hotelDetail: {
    name: string
    address: string
    city: string
    state: string
    zip: string
    country: string
    timezone_area: string
    group: string
    mgmt_company: string
    image: string
  }
  hotelRolePermission: {
    templates: {
      department_name: string
      role_name: string
    }[]
    permissions: {
      [key: string]: {
        title: string
        description: any
        template_permissions: {
          id: string
          role_name: string
          is_changeable: boolean
          access_level: number
          is_checked: boolean
          show_access_selector: boolean
          is_disabled: boolean
        }[]
      }[]
    }
  }
}

const initialState: RoleStateType = {
  requestItems: [],
  deliveryServiceListData: [],
  hotelRolePermission: {
    templates: [],
    permissions: {},
  },
  hotelDetail: {
    name: '',
    address: '',
    city: '',
    state: '',
    zip: '',
    country: '',
    timezone_area: '',
    group: '',
    mgmt_company: '',
    image: '',
  },
}

export default (state = initialState, action: any) => {
  const { type, data } = action
  switch (type) {
    case action_types.REQUESTITEMS:
      return { ...state, requestItems: data }

    case action_types.DELIVERY_SERVICE_LIST:
      return { ...state, deliveryServiceListData: data }

    case action_types.DELIVERY_SERVICE_POP:
      return { ...state, deliveryServicePopData: data }

    case action_types.HOTEL_ROLE_PERMISSION:
      return { ...state, hotelRolePermission: data }

    case action_types.HOTEL_DETAILS:
      return { ...state, hotelDetail: data }

    default:
      return state
  }
}
