import { NotesDocType } from '@/redux/notes/types'
import { RoomType } from '@/redux/rooms/types'
import { ActiveRoomType } from './types'

const getLastUpdated = (docs: NotesDocType[]) =>
  docs.reduce(
    (prev, current) =>
      Date.parse(prev.update_time) > Date.parse(current.update_time) ? prev : current,
    docs[0],
  )

export const pinNote = (docs: NotesDocType[]): NotesDocType => getLastUpdated(docs)

export const unpinNote = (
  docs: NotesDocType[],
  pinnedNote: NotesDocType | null,
): NotesDocType | null => {
  const note = getLastUpdated(docs)
  if (pinnedNote?.id === note.id) return null
  return pinnedNote
}

export const updateRoom = (rooms: RoomType[], activeRoom: ActiveRoomType): ActiveRoomType => {
  const room = rooms.find(({ id }) => activeRoom.id === id)
  return room ? { ...activeRoom, ...room } : activeRoom
}
