/* eslint-disable @typescript-eslint/naming-convention */
import { RoomType } from '@/redux/rooms/types'
import {
  allocateNormalize,
  basketNormalize,
  initAllocatedNormalize,
} from '@/redux/allocations/service'
import { IRootState } from '../reducers'

export const getBasket = (state: IRootState): Record<string, string[]> => {
  const { floor, status, reservation_status } = state.allocations.filters
  const basket = state.allocations.allocated?.basket ?? []
  const uniqueNames: any[] = Array.from(new Set(basket))
  const filtered = Object.values(state.allocations.rooms)
    .filter((room: RoomType) => {
      return (
        (floor && floor?.length > 0 ? floor.find((e: any) => room.floor === e) : true) &&
        (status && status?.length > 0 ? status.find((e: any) => room.status === e) : true) &&
        (reservation_status && reservation_status?.length > 0
          ? reservation_status.find((e: any) => room.reservation_status === e)
          : true)
      )
    })
    .reduce((acc: string[], room) => [...acc, room.id], [])
  return {
    basket: uniqueNames.filter((item: any) => filtered.includes(item)),
  }
}

export const getInitAllocatedState = (state: IRootState): Record<string, string[]> => {
  const initAllocated = initAllocatedNormalize(state.allocations.rooms)
  const allocated = allocateNormalize(state.allocations.attendants, initAllocated)

  allocated.basket = basketNormalize(state.allocations.rooms)
  return allocated
}

export const isChanged = (state: IRootState): boolean => {
  return Object.keys(state.allocations.history).length > 0
}
