import { UserType } from '@/redux/user/types'
import { timezoneList } from '@/views/AddHotel/components/data/timezones'
import { isToday, isTomorrow, isYesterday, isThisWeek, format } from 'date-fns'
import moment from 'moment-timezone'
import store from '../redux/store'

export const getTimeZone = (): string => {
  const result = (store.getState() as any).user.user
  return result?.timezone_area ?? 'America/New_York'
}

export const getDate = (date: Date | string | number): Date => {
  if (date instanceof Date) {
    return date
  }

  if (['string', 'number'].includes(typeof date)) {
    return new Date(date)
  }

  return new Date()
}

export const formatDate = (date: Date | string | number, onlyDay = false): string => {
  const currentDate = getDate(date)
  const timezone = getTimeZone()
  if (isTomorrow(currentDate)) {
    return onlyDay ? `Tomorrow` : `Tomorrow, ${moment(currentDate).tz(timezone).format('hh:mm a')}`
  }

  if (isToday(currentDate)) {
    return onlyDay ? `Today` : `Today, ${moment(currentDate).tz(timezone).format('hh:mm a')}`
  }

  if (isYesterday(currentDate)) {
    return onlyDay
      ? `Yesterday`
      : `Yesterday, ${moment(currentDate).tz(timezone).format('hh:mm a')}`
  }

  if (isThisWeek(currentDate)) {
    return `${moment(currentDate)
      .tz(timezone)
      .format(onlyDay ? 'ddd' : 'ddd, hh:mm a')}`
  }

  return moment(currentDate)
    .tz(timezone)
    .format(onlyDay ? 'MMM DD' : 'MMM DD, hh:mm a')
}

export const formatToDayAndMonth = (date: string): string => `${format(getDate(date), 'MMM d')}`

export const formatToHour = (date: string): string => {
  return moment(new Date(date)).tz(getTimeZone()).format('h:mm A')
}

export const formatShiftTime = (start: string, end: string): string => {
  const timezone = getTimeZone()
  return `${moment(getDate(start)).tz(timezone).format('h:mm A')} - ${moment(getDate(end))
    .tz(timezone)
    .format('h:mm A')}`
}
