import Icon from '@ant-design/icons'

const KebabSvg = () => (
  <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="22.5996" y="13.6" width="2.8" height="2.8" rx="1.4" fill="#1B1C1D" />
    <rect x="22.5996" y="22.6" width="2.8" height="2.8" rx="1.4" fill="#1B1C1D" />
    <rect x="22.5996" y="31.6" width="2.8" height="2.8" rx="1.4" fill="#1B1C1D" />
  </svg>
)

const KebabIcon = (props: any) => <Icon component={KebabSvg} {...props} />

export default KebabIcon
