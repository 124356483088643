import Icon from '@ant-design/icons'

const SuccessSvg = () => (
  <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="36" height="36" rx="18" fill="#E6F9F0" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M26.0815 11.3938C26.4164 11.7149 26.4275 12.2467 26.1065 12.5815L16.0384 23.0815C15.88 23.2467 15.661 23.3402 15.4321 23.3402C15.2032 23.3402 14.9842 23.2467 14.8258 23.0815L11.3938 19.5024C11.0728 19.1675 11.0839 18.6358 11.4188 18.3147C11.7536 17.9936 12.2854 18.0048 12.6065 18.3396L15.4321 21.2865L24.8938 11.4188C25.2149 11.0839 25.7467 11.0728 26.0815 11.3938Z"
      fill="#0B9F57"
    />
  </svg>
)

const SuccessIcon = (props: any) => <Icon component={SuccessSvg} {...props} />

export default SuccessIcon
