import { RoomState } from '@/views/Rooms/ducks/reducers'
import { NotesType, RoomListForSocket } from '@/views/Rooms/ducks/types'
import { LabelDisplayedRowsArgs } from '@material-ui/core/TablePagination/TablePagination'

export const resolveUserRole = (role: string): string => {
  switch (role) {
    case 'Admin':
      return role
    case 'Manager':
      return 'Housekeeping Manager'
    case 'Attendant':
      return 'Room Attendant'
    default:
      return ''
  }
}

export const departments = (key: string) => {
  switch (key) {
    case 'manager':
    case 'Manager':
    case 'housekeeping_manager':
    case 'attendant':
    case 'Attendant':
    case 'houseman':
    case 'minibar_attendant':
    case 'housekeeping_supervisor':
    case 'housekeeping_inspector':
    case 'house_person':
    case 'laundry_attendant':
    case 'linen_house_person':
    case 'public_area_house_person':
    case 'residential_house_person':
    case 'uniform_attendant':
      return 'housekeeping'

    case 'steward':
    case 'stewarding_manager':
      return 'stewarding'

    case 'engineer':
    case 'engineering_manager':
    case 'engineering_supervisor':
      return 'engineering'

    case 'food_and_beverage':
    case 'food_beverage_manager':
    case ' ird_server':
    case 'breakfast_attendant':
      return 'food_and_beverage'

    case 'front_desk':
    case 'front_desk_manager':
    case 'doorman':
    case 'bellman':
    case 'pool_attendant':
      return 'front_desk'

    case 'security':
    case 'security_agent':
    case 'security_manager':
    case 'security_supervisor':
      return 'security'

    case 'overnight_cleaning':
    case 'cleaner':
    case 'cleaning_supervisor':
      return 'overnight_cleaning'

    case 'concierge':
      return 'concierge'

    default:
      return ''
  }
}

export const departmentForDisplay = (role: string): string => {
  switch (role) {
    case 'Manager':
      return 'Housekeeping'
    case 'houseman':
      return 'Housekeeping'
    case 'housekeeping_manager':
      return 'Housekeeping'
    case 'Attendant':
    case 'attendant':
      return 'Housekeeping'

    case 'minibar_attendant':
    case 'housekeeping_supervisor':
    case 'housekeeping_inspector':
    case 'house_person':
    case 'laundry_attendant':
    case 'linen_house_person':
    case 'public_area_house_person':
    case 'residential_house_person':
    case 'uniform_attendant':
      return 'Housekeeping'

    case 'engineer':
      return 'Engineering'
    case 'engineering_manager':
    case 'engineering_supervisor':
      return 'Engineering'

    case 'steward':
      return 'Stewarding'
    case 'stewarding_manager':
    case 'stewarding_supervisor':
      return 'Stewarding'

    case 'front_desk':
    case 'front_desk_manager':
    case 'doorman':
    case 'bellman':
    case 'pool_attendant':
      return 'Front Desk'

    case 'food_and_beverage':
    case 'food_beverage_manager':
    case ' ird_server':
    case 'breakfast_attendant':
      return 'Food and Beverage'

    case 'security_agent':
    case 'security_manager':
    case 'security_supervisor':
      return 'Security'

    case 'concierge':
      return 'Concierge'

    case 'concierge_manager':
      return 'Concierge Manager'

    case 'cleaner':
    case 'cleaning_supervisor':
      return 'Overnight Cleaning'

    default:
      return ''
  }
}

export const userType: { [key: string]: string } = {
  location_admin: 'Location Admin',
  maitre_admin: 'Maitre Admin',
  location_user: 'Location User',
}

export const userRolesForDisplay = (text: string) => {
  if (!text) {
    return '-'
  }
  if (text?.toLowerCase() === 'attendant') {
    return 'Room Attendant'
  }

  if (text?.toLowerCase() === 'admin') {
    return 'Maitre Admin'
  }

  if (text === 'ird_server') {
    return 'IRD Server'
  }

  const words = text
    ?.toLowerCase()
    ?.split('_')
    ?.map((word) => {
      return word?.charAt(0)?.toUpperCase() + word?.slice(1)
    })
  return words?.join(' ')
}

// export const userRolesForDisplay: { [key: string]: string } = {
//   Admin: 'admin',
//   manager: 'Housekeeping Manager',
//   // Manager: 'Housekeeping Manager',
//   attendant: 'Room Attendant',
//   // Attendant: 'Room Attendant',
//   // houseman: 'Houseman',
//   housekeeping_manager: 'Housekeeping Manager',
//   // steward: 'Steward',
//   // stewarding_manager: 'Stewarding Manager',
//   // engineer: 'Engineer',
//   // engineering_manager: 'Engineering Manager',
//   // delivery_service: 'Delivery Service',
//   flower_delivery: 'Delivery Service',
//   water_delivery: 'Delivery Service',
//   food_delivery: 'Delivery Service',
//   linen_delivery: 'Delivery Service',
//   houseman: 'House Person',
//   engineer: 'Engineer',
//   breakfast_attendant: 'Breakfast Attendant',
//   ird_server: 'IRD Server',
//   ird_Server: 'IRD Server',
//   bellman: 'Bellman',
//   doorman: 'Doorman',
//   pool_attendant: 'Pool Attendant',
//   house_person: 'House Person',
//   laundry_attendant: 'Laundry Attendant',
//   linen_house_person: 'Linen House Person',
//   public_area_house_person: 'Public Area House Person',
//   residential_house_person: 'Residential House Person',
//   uniform_attendant: 'Uniform Attendant',
//   security_agent: 'Security Agent',
//   concierge: 'Concierge',
//   cleaner: 'Cleaner',
//   Attendant: 'Room Attendant',
//   minibar_attendant: 'Minibar Attendant',
//   steward: 'Steward',
//   cleaning_supervisor: 'Cleaning Supervisor',
//   engineering_manager: 'Engineering Manager',
//   engineering_supervisor: 'Engineering Supervisor',
//   housekeeping_inspector: 'Housekeeping Inspector',
//   Manager: 'Housekeeping Manager',
//   housekeeping_supervisor: 'Housekeeping Supervisor',
//   security_manager: 'Security Manager',
//   security_supervisor: 'Security Supervisor',
//   security_Supervisor: 'Security Supervisor',
//   stewarding_manager: 'Stewarding Manager',
//   stewarding_supervisor: 'Stewarding Supervisor',
//   front_desk_manager: 'Front Desk Manager',
//   food_beverage_manager: 'Food & Beverage Manager',
//   delivery_service: 'Delivery Service',
//   concierge_manager: 'Concierge Manager',
// }

export const STAFF_ROLES: any = {
  houseman: 'House Person',
  engineer: 'Engineer',
  breakfast_attendant: 'Breakfast Attendant',
  ird_server: 'IRD Server',
  ird_Server: 'IRD Server',
  bellman: 'Bellman',
  doorman: 'Doorman',
  pool_attendant: 'Pool Attendant',
  laundry_attendant: 'Laundry Attendant',
  linen_house_person: 'Linen House Person',
  public_area_house_person: 'Public Area House Person',
  residential_house_person: 'Residential House Person',
  uniform_attendant: 'Uniform Attendant',
  security_agent: 'Security Agent',
  concierge: 'Concierge',
  cleaner: 'Cleaner',
  Attendant: 'Room Attendant',
  minibar_attendant: 'Minibar Attendant',
  steward: 'Steward',
}

export const MANAGER_ROLES: any = {
  cleaning_supervisor: 'Cleaning Supervisor',
  engineering_manager: 'Engineering Manager',
  engineering_supervisor: 'Engineering Supervisor',
  housekeeping_inspector: 'Housekeeping Inspector',
  Manager: 'Housekeeping Manager',
  housekeeping_supervisor: 'Housekeeping Supervisor',
  security_manager: 'Security Manager',
  security_supervisor: 'Security Supervisor',
  security_Supervisor: 'Security Supervisor',
  stewarding_manager: 'Stewarding Manager',
  stewarding_supervisor: 'Stewarding Supervisor',
  front_desk_manager: 'Front Desk Manager',
  food_and_beverage_manager: 'Food & Beverage Manager',
  food_beverage_manager: 'Food & Beverage Manager',
}

export const userServicesForDisplay: { [key: string]: string } = {
  flower_delivery: 'Flower',
  water_delivery: 'Water',
  food_delivery: 'Food',
  linen_delivery: 'Linen',
}

export const deliveryDays: { [key: string]: string } = {
  Mo: 'Mon',
  Tu: 'Tue',
  We: 'Wed',
  Th: 'Thu',
  Fr: 'Fri',
  Sa: 'Sat',
  Su: 'Sun',
  DAILY: 'Daily',
}

export const formatStringByMask = (value: string | undefined, pattern: string): string | null => {
  if (value) {
    let i = 0
    const v = value.toString()
    // eslint-disable-next-line no-plusplus
    return pattern.replace(/#/g, () => v[i++])
  }
  return null
}

export const formatTablePagination = (paginationInfo: LabelDisplayedRowsArgs): string => {
  return `${paginationInfo.from}-${paginationInfo.to} items of ${paginationInfo.count}`
}

export const templateRoles: any = {
  generic_administration: 'Administration',
  generic_manager: 'Manager',
  generic_staff: 'Staff',
  room_attendant: 'Room Attendant',
  general_manager: 'General Manager',
  front_desk_manager: 'Front Desk Manager',
  housekeeping_manager: 'Housekeeping Manager',
  house_person: 'House Person',
}

export const permissionDisplayNames: any = {
  operations: 'Operations',
  dashboard_and_reports: 'Dashboard and Reports',
  rooms: 'Rooms',
  tasks: 'Tasks',
  checklists_and_sop: 'Checklist and SOP',
  projects: 'Projects',
  schedule: 'Schedule',
}

export const notesGroupBy = (data: NotesType[]) => {
  const groups: { [key: string]: NotesType[] } = data.reduce((initialGroups: any, notes) => {
    const date: string = notes.date.split('T')[0]
    if (!initialGroups[date]) {
      initialGroups[date] = []
    }
    initialGroups[date].push(notes)
    return initialGroups
  }, {})

  const groupBy = Object.keys(groups).map((date) => {
    return {
      date,
      data: groups[date],
    }
  })
  return groupBy
}

export const roomsLayoutForSocket = (data: RoomState['roomCardListing']) => {
  if (Object.keys(data?.roomList)?.length > 0) {
    const convertedData: RoomListForSocket = {}
    for (const floor in data?.roomList) {
      if (floor) {
        data?.roomList[floor].forEach((item, index) => {
          convertedData[item.id] = { floor, index }
        })
      }
    }
    return convertedData
  }
  return null
}
