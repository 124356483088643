import { toast } from 'react-toastify'
import {
  all,
  takeEvery,
  put,
  call,
  select,
  take,
  race,
  spawn,
  takeLatest,
} from 'redux-saga/effects'
import { SagaIterator } from '@redux-saga/types'
import messages from '@/config/messages'
import {
  getAutoAllocationService,
  getRoomsWithAttendants as getRoomsWithAttendantsService,
  saveChanges as saveChangesService,
} from '@/services/allocations'
import { RoomsResponseType, RoomType } from '@/redux/rooms/types'
import { fetchWorkShiftsList } from '@/services/shifts'
import { ShiftType, ShiftUserType } from '@/redux/shifts/types'
import { formatShiftTime } from '@/utils/dates'
import {
  initAllocatedNormalize,
  allocateNormalize,
  basketNormalize,
} from '@/redux/allocations/service'
import { IRootState } from '@/redux/reducers'
import { getInitAllocatedState } from '@/redux/allocations/selectors'
import { DragHistoryType } from '@/redux/allocations/types'
import { AnyAction } from 'redux'
import { IS_DEV } from '@/config/constants'
import actions, {
  updating,
  loading,
  setRoomsWithAttendantsSuccess,
  setRoomsAttendantsSuccess,
  setAutoAllocate,
  saveSkipped,
} from './actions'
// import eventChannel from './websocket'

export function* getRoomsWithAttendants(data: any): SagaIterator {
  yield put(loading(true))
  try {
    const [allocationsData, shifts]: [RoomsResponseType, ShiftType[]] = yield all([
      call(
        getRoomsWithAttendantsService,
        data?.payload?.hotelID,
        data?.payload?.date,
        data?.payload?.type,
      ),
      call(fetchWorkShiftsList, data?.payload?.hotelID, data?.payload?.date, data?.payload?.type),
    ])

    const attendants = shifts.reduce((prev: ShiftUserType[], current: ShiftType) => {
      const slot = formatShiftTime(current.start, current.end)
      return [
        ...prev,
        {
          ...current.user,
          slot,
          workshift_id: current.workshift_id,
          display_role: current.display_role,
        },
      ]
    }, [])

    const initAllocated = initAllocatedNormalize(allocationsData.rooms)
    const allocated = allocateNormalize(attendants, initAllocated)

    allocated.basket = basketNormalize(allocationsData.rooms)

    yield put(setRoomsWithAttendantsSuccess({ ...allocationsData, allocated, attendants }))
  } catch (e) {
    toast.error(e?.response?.data?.error || messages.responseError)
  } finally {
    yield put(loading(false))
  }
}

export function* getAutoAllocate(payload: any): SagaIterator {
  yield put(updating(true))
  try {
    const allocationsData: { [k in string]: string[] }[] = yield call(
      getAutoAllocationService,
      payload?.payload?.hotelID,
      payload?.payload?.type,
      payload?.payload?.roomIds,
    )

    const stateAllocated: { [k in string]: string[] } = yield select(
      (state: IRootState) => state.allocations.allocated,
    )

    const initAllocatedState: Record<string, string[]> = yield select(getInitAllocatedState)

    const attendants: ShiftUserType[] = yield select(
      (state: IRootState) => state.allocations.attendants,
    )

    const data = allocationsData.reduce(
      (acc: { [x: string]: string[] }, item: { [x: string]: string[] }) => ({
        ...acc,
        ...item,
      }),
      {},
    )

    const allocated = allocateNormalize(attendants, data)

    const allocatedMap = Object.values(data).flat()

    allocated.basket = Object.values(stateAllocated)
      .flat()
      .filter((item: string) => !allocatedMap.includes(item))

    const history = Object.entries(initAllocatedState).reduce<DragHistoryType>(
      (acc, [key, values]: [string, string[]]) => {
        return {
          ...acc,
          ...values.reduce<DragHistoryType>((carry, item, index) => {
            if (allocated?.[key] && allocated?.[key].includes(item)) {
              return carry
            }

            return { ...carry, [item]: { id: key, index } }
          }, {}),
        }
      },
      {},
    )
    yield put(setAutoAllocate({ allocated, history }))
  } catch (e) {
    toast.error(e?.response?.data?.error || messages.responseError)
  } finally {
    yield put(updating(false))
  }
}

export function* saveChanges({ data }: AnyAction): SagaIterator {
  yield put(updating(true))

  const { basket, ...rest } = yield select((state: IRootState) => state.allocations.allocated)

  const body = {
    hotelId: data?.HotelID,
    allocation: Object.entries(rest || {}).reduce<{ workshift: string; rooms: string[] }[]>(
      (acc, [key, item]) => [...acc, { workshift: key, rooms: item as string[] }],
      [],
    ),
  }

  try {
    yield call(saveChangesService, data.statusType, body)
    const allocationsData = yield call(
      getRoomsWithAttendantsService,
      data?.HotelID,
      data?.date,
      data?.statusType,
    )
    yield put(setRoomsAttendantsSuccess(allocationsData))
    data?.afterSubmit?.()
  } catch (e) {
    toast.error(e?.response?.data?.error || messages.responseError)
  } finally {
    yield put(updating(false))
  }
}

export function* skipAndSend(): SagaIterator {
  yield put(updating(true))
  try {
    const stateHistory: DragHistoryType = yield select(
      (state: IRootState) => state.allocations.history,
    )

    const stateRooms: { [k: string]: RoomType } = yield select(
      (state: IRootState) => state.allocations.rooms,
    )

    const stateAllocated: { [k in string]: string[] } = yield select(
      (state: IRootState) => state.allocations.allocated,
    )

    const cleaningRooms = Object.values(stateRooms)
      .filter((item) => stateHistory?.[item.id] && item.is_cleaning)
      .reduce<string[]>((acc, item) => [...acc, item.id], [])

    const allocated: Record<string, string[]> = Object.entries(stateAllocated).reduce(
      (acc, [key, values]) => ({
        ...acc,
        [key]: values.filter((item) => !cleaningRooms.includes(item)),
      }),

      {},
    )

    cleaningRooms.forEach((key) => {
      const item = stateHistory[key]
      if (item?.id && item?.index !== undefined && allocated?.[item.id]) {
        if (allocated[item.id].length > item.index) {
          const clone = [...allocated[item.id]]
          clone.splice(item.index, 0, key)
          allocated[item.id] = clone
        } else {
          allocated[item.id].push(key)
        }
      }
    })

    yield put(saveSkipped(allocated))
    // yield call(saveChanges, { type: actions.SAVE_CHANGES })
  } catch (e) {
    toast.error(e?.response?.data?.error || messages.responseError)
  } finally {
    yield put(updating(false))
  }
}

function* listenRoomsSaga({ id }: AnyAction): SagaIterator {
  // const roomChannel = yield call(eventChannel, id)
  // try {
  //   while (true) {
  //     const action = yield take(roomChannel)
  //     yield put(action)
  //   }
  // } catch (e) {
  //   if (IS_DEV) {
  //     // eslint-disable-next-line no-console
  //     console.log('listenRoomsSagaError', {
  //       message: (e as Error).message,
  //     })
  //   }
  //   toast.error(messages.socketError)
  // } finally {
  //   roomChannel?.close?.()
  // }
}

function* websocketChannel(): SagaIterator {
  while (true) {
    const action = yield take(actions.START_ALLOCATION_SOCKET_CHANNEL)
    try {
      yield race({
        task: call(listenRoomsSaga, action),
        cancelTask: take(actions.CLOSE_ALLOCATION_SOCKET_CHANNEL),
      })
    } catch (e) {
      if (IS_DEV) {
        // eslint-disable-next-line no-console
        console.log('watchRoomsSagaError', {
          message: (e as Error).message,
        })
      }
      toast.error(messages.socketError)
    }
  }
}

export default function* rootSaga(): SagaIterator {
  yield spawn(websocketChannel)
  yield all([
    takeEvery(actions.GET_ROOMS_WITH_ATTENDANTS_REQUEST, getRoomsWithAttendants),
    takeEvery(actions.GET_AUTO_ALLOCATE, getAutoAllocate),
    takeEvery(actions.SKIP_AND_SEND, skipAndSend),
    takeLatest(actions.SAVE_CHANGES, saveChanges),
  ])
}
