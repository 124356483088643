export const USERLISTING = 'USERLISTING'
export const USERLISTFILTERS = 'USERLISTFILTERS'
export const CREATEUSER = 'CREATEUSER'
export const CHECKUSEREXISTS = 'CREATEUSEREXISTS'
export const USERFORMDATA = 'USERFORMDATA'
export const USERDETAILS = 'USERDETAILS'
export const GETHOTELROLE = 'GETHOTELROLE'
export const USER_SET_STATE = 'USER_SET_STATE'
export const USER_DETAIL_SET_STATE = 'USER_DETAIL_SET_STATE'
export const GET_UKG_CREDENTIALS = 'GET_UKG_CREDENTIALS'
