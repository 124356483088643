import Icon from '@ant-design/icons'

const EditSvg = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.6231 2.79628C13.3591 2.05988 14.5527 2.05938 15.2893 2.79516L17.2029 4.70665C17.9399 5.44273 17.9404 6.63689 17.2041 7.3736L8.1704 16.4122C7.90778 16.675 7.57339 16.8543 7.2092 16.9277L3.17379 17.7408C2.91885 17.7922 2.65512 17.7125 2.47127 17.5285C2.28743 17.3446 2.2079 17.0808 2.25942 16.8259L3.07399 12.7952C3.14744 12.4318 3.32641 12.0981 3.58852 11.8358L12.6231 2.79628ZM14.1919 3.89377C14.0619 3.76393 13.8513 3.76402 13.7214 3.89397L4.68682 12.9335C4.64056 12.9798 4.60898 13.0387 4.59602 13.1028L4.013 15.9877L6.90248 15.4055C6.96675 15.3925 7.02576 15.3609 7.07211 15.3145L16.1058 6.2759C16.2357 6.1459 16.2356 5.93516 16.1056 5.80526L14.1919 3.89377Z"
    />
  </svg>
)

const EditIconNew = (props: any) => <Icon component={EditSvg} {...props} />

export default EditIconNew
