import Icon from '@ant-design/icons'

const CalendarSvg = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.80951 0.549927C5.19611 0.549927 5.50951 0.863327 5.50951 1.24993V2.04993H14.3V1.24993C14.3 0.863327 14.6134 0.549927 15 0.549927C15.3866 0.549927 15.7 0.863327 15.7 1.24993V2.11605C17.4087 2.44325 18.7 3.94579 18.7 5.74993L18.7 15.75C18.7 17.7935 17.0434 19.45 15 19.45H4.99999C2.95653 19.45 1.29999 17.7935 1.29999 15.75V5.74993C1.29999 4.01347 2.49619 2.55639 4.10951 2.15779V1.24993C4.10951 0.863327 4.42291 0.549927 4.80951 0.549927ZM15 3.44993H4.99999C3.72973 3.44993 2.69999 4.47967 2.69999 5.74993V15.75C2.69999 17.0203 3.72973 18.05 4.99999 18.05H15C16.2702 18.05 17.3 17.0203 17.3 15.75L17.3 5.74993C17.3 4.47967 16.2702 3.44993 15 3.44993ZM4.72856 6.24993C4.72856 5.86333 5.04196 5.54993 5.42856 5.54993H14.5C14.8866 5.54993 15.2 5.86333 15.2 6.24993C15.2 6.63653 14.8866 6.94993 14.5 6.94993H5.42856C5.04196 6.94993 4.72856 6.63653 4.72856 6.24993Z"
      fill="black"
    />
  </svg>
)

const CalendarIcon = (props: any) => <Icon component={CalendarSvg} {...props} />

export default CalendarIcon
