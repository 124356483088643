import { AnyAction } from 'redux'
import { ActiveRoomType } from '@/redux/room/types'
import { NotesDocType } from '@/redux/notes/types'
import { RoomType } from '@/redux/rooms/types'

const actions = {
  SET_STATE: 'room/SET_STATE',
  GET_ONE: 'room/GET_ONE',
  GET_ONE_BY_DATE: 'room/GET_ONE_BY_DATE',
  SAVE_ONE: 'room/SAVE_ONE',
  CLEAR_DATA: 'room/CLEAR_DATA',
  CHANGE_ACTIVE_ROOM_STATUS: 'room/CHANGE_ACTIVE_ROOM_STATUS',
  UPDATE_ACTIVE_ROOM_BY_SOCKET: 'room/UPDATE_ACTIVE_ROOM_BY_SOCKET',
  PIN_NOTE: 'room/PIN_NOTE',
  UNPIN_NOTE: 'room/UNPIN_NOTE',
  START_ROOM_DETAILS_SOCKET_CHANNEL: 'room/START_ROOM_DETAILS_SOCKET_CHANNEL',
  CLOSE_ROOM_DETAILS_SOCKET_CHANNEL: 'room/CLOSE_ROOM_DETAILS_SOCKET_CHANNEL',
}

export const setState = (payload: unknown): AnyAction => {
  return {
    type: actions.SET_STATE,
    payload,
  }
}

export const loading = (data: boolean): AnyAction => {
  return {
    type: actions.SET_STATE,
    payload: { loading: data },
  }
}

export const clearData = (): AnyAction => {
  return {
    type: actions.CLEAR_DATA,
  }
}

export const getOne = (id: string): AnyAction => {
  return {
    type: actions.GET_ONE,
    payload: { id },
  }
}

export const getOneByDate = (id: string, date: string): AnyAction => {
  return {
    type: actions.GET_ONE_BY_DATE,
    payload: { id, date },
  }
}

export const saveOne = (room: ActiveRoomType): AnyAction => {
  return {
    type: actions.SAVE_ONE,
    payload: { room },
  }
}

export const changeActiveRoomStatus = (status: string): AnyAction => {
  return {
    type: actions.CHANGE_ACTIVE_ROOM_STATUS,
    status,
  }
}

export const updateActiveRoomBySocket = (rooms: RoomType[]): AnyAction => {
  return {
    type: actions.UPDATE_ACTIVE_ROOM_BY_SOCKET,
    rooms,
  }
}

export const pinNote = (notes: NotesDocType[]): AnyAction => {
  return {
    type: actions.PIN_NOTE,
    notes,
  }
}

export const unpinNote = (notes: NotesDocType[]): AnyAction => {
  return {
    type: actions.UNPIN_NOTE,
    notes,
  }
}

export const startRoomDetailsSocketChannel = (id: string): AnyAction => {
  return {
    type: actions.START_ROOM_DETAILS_SOCKET_CHANNEL,
    id,
  }
}

export const closeRoomDetailsSocketChannel = (): AnyAction => {
  return {
    type: actions.CLOSE_ROOM_DETAILS_SOCKET_CHANNEL,
  }
}

export default actions
