import Icon from '@ant-design/icons'

const DropDownSvg = () => (
  <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M2 5L7 10L12 5"
      stroke="#1B1C1D"
      strokeWidth="1.3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

const ArrowIcon = (props: any) => <Icon component={DropDownSvg} {...props} />

export default ArrowIcon
