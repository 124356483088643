import * as action_types from './constants'

const initialState = {
  rolesData: {},
  departmentData: [],
}

export default (state = initialState, action: any) => {
  const { type, data } = action
  switch (type) {
    case action_types.ROLE_FORM_DATA:
      return { ...state, rolesData: data }

    case action_types.DEPARTMENT_DATA:
      return { ...state, departmentData: data }

    default:
      return state
  }
}
