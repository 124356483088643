import React from 'react'
import { Redirect } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import useAuth from '@/context/AuthContext'
import LoadingSpinner from '@/components/Spinners/LoadingSpinner'
import navigations from '@/config/navigations'
import { useAppSelector } from '@/redux/store'
import { LayoutProps } from '../types'

const useStyles = makeStyles(() => ({
  layout: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
  },
  '@global': {
    body: {
      backgroundColor: '#F5F5F5',
    },
  },
}))

const FullLayout: React.FunctionComponent<LayoutProps> = ({ children, location }: LayoutProps) => {
  const classes = useStyles()
  // const { authenticated, isPending, initialPending } = useAuth()
  const user = useAppSelector((state) => state?.user?.user)

  if (user) {
    return <Redirect to="/" />
  }

  // if (initialPending) {
  //   return <LoadingSpinner />
  // }

  return <div className={classes.layout}>{children}</div>
}

export default FullLayout
