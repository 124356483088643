import { AnyAction } from 'redux'
import { FloorType } from './types'
import { updateRoomById, updateRoomsBySocket } from './service'
import actions from './actions'

export interface RoomsStateType {
  floors: FloorType[]
  loading: boolean
  uploading: boolean
  totalFloors: number
  filter: {
    [k in string]: string | null
  }
}

const initialState: RoomsStateType = {
  floors: [],
  loading: false,
  uploading: false,
  totalFloors: 0,
  filter: {
    status: null,
    floor: null,
    attendant: null,
  },
}

export default function reducer(
  state: RoomsStateType = initialState,
  action: AnyAction,
): RoomsStateType {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload }
    case actions.SAVE_FILTER:
      return { ...state, filter: { ...state.filter, ...action.payload.filter } }
    case actions.UPDATE_ROOM_BY_ID:
      return { ...state, floors: updateRoomById([...state.floors], action.payload) }
    case actions.UPDATE_ROOMS_BY_SOCKET:
      return { ...state, floors: updateRoomsBySocket([...state.floors], action.rooms) }
    case actions.CLEAR_DATA:
      return {
        ...state,
        filter: { status: null, floor: null, attendant: null },
        floors: [],
        totalFloors: 0,
      }
    default:
      return state
  }
}
