import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import store from './redux/store'
import App from './App'
import './index.scss'
import reportWebVitals from './reportWebVitals'
import './init'

// if (process.env.NODE_ENV !== 'development') {
//   // eslint-disable-next-line @typescript-eslint/no-var-requires,global-require
//   const Sentry = require('@sentry/react')
//   // eslint-disable-next-line @typescript-eslint/no-var-requires,global-require
//   const { Integrations } = require('@sentry/tracing')
//
//   Sentry.init({
//     dsn: 'https://bab5e41d68094f8bb9f1aeb8b46a62ee@o549716.ingest.sentry.io/5672801',
//     integrations: [new Integrations.BrowserTracing()],
//     tracesSampleRate: 1.0,
//   })
// }

const hasRefreshed = localStorage.getItem('hasRefreshed')
if (!hasRefreshed) {
  localStorage.setItem('hasRefreshed', 'true')
  window.location.reload()
}

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById('root'),
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
