import { AnyAction } from 'redux'
import { UserType } from '@/redux/user/types'
import {
  ChangeStatusType,
  FloorType,
  LoadCSVFileType,
  RoomType,
  ChangeStatusTypeWithData,
  LoadAllocationCSVFileType,
  LoadProductivityCSVFileType,
} from './types'

const actions = {
  SET_STATE: 'rooms/SET_STATE',
  GET_ROOMS_LIST: 'rooms/GET_ROOMS_LIST',
  SAVE_FILTER: 'rooms/SAVE_FILTER',
  UPLOAD_CSV_FILE: 'rooms/UPLOAD_CSV_FILE',
  EXPORT_FILE: 'rooms/EXPORT_FILE',
  EXPORT_FILE_BY_DATE: 'rooms/EXPORT_FILE_BY_DATE',
  EXPORT_ALLOCATION_FILE_BY_DATE: 'rooms/EXPORT_ALLOCATION_FILE_BY_DATE',
  EXPORT_PRODUCTIVITY_FILE_BY_DATE: 'rooms/EXPORT_PRODUCTIVITY_FILE_BY_DATE',
  CLEAR_DATA: 'rooms/CLEAR_DATA',
  CHANGE_ROOM_STATUS: 'rooms/CHANGE_ROOM_STATUS',
  CHANGE_ROOM_STATUS_WITH_DATA: 'rooms/CHANGE_ROOM_STATUS_WITH_DATA',
  RETURN_STATUS_TO_ORDER: 'rooms/RETURN_STATUS_TO_ORDER',
  START_ROOMS_SOCKET_CHANNEL: 'rooms/START_ROOMS_SOCKET_CHANNEL',
  CLOSE_ROOMS_SOCKET_CHANNEL: 'rooms/CLOSE_ROOMS_SOCKET_CHANNEL',
  UPDATE_ROOMS_BY_SOCKET: 'rooms/UPDATE_ROOMS_BY_SOCKET',
  UPDATE_ROOM_BY_ID: 'rooms/UPDATE_ROOM_BY_ID',
  CLOSE_CSV_MODAL: 'rooms/CLOSE_CSV_MODAL',
  SAVE_WORKSHIFT_ORDER: 'rooms/SAVE_WORKSHIFT_ORDER',
}

export const setState = (payload: unknown): AnyAction => {
  return {
    type: actions.SET_STATE,
    payload,
  }
}

export const loading = (data: boolean): AnyAction => {
  return {
    type: actions.SET_STATE,
    payload: { loading: data },
  }
}

export const uploading = (data: boolean): AnyAction => {
  return {
    type: actions.SET_STATE,
    payload: { uploading: data },
  }
}

export const saveRoomsFilter = (type: string, value: unknown): AnyAction => {
  return {
    type: actions.SAVE_FILTER,
    payload: { filter: { [type]: value } },
  }
}

export const saveRoomsList = (payload: { floors: FloorType[]; totalFloors: number }): AnyAction => {
  return {
    type: actions.SET_STATE,
    payload,
  }
}

export const getRoomsList = (filter: { [k in string]: string | null }, date: string): AnyAction => {
  return {
    type: actions.GET_ROOMS_LIST,
    payload: { filter, date },
  }
}

export const uploadCsvFile = (file: File, onUploadSuccess?: () => void): AnyAction => {
  return {
    type: actions.UPLOAD_CSV_FILE,
    payload: { file, onUploadSuccess },
  }
}

export const exportCsvFile = (payload: LoadCSVFileType): AnyAction => {
  return {
    type: actions.EXPORT_FILE,
    payload,
  }
}

export const exportCsvFileByDate = (payload: LoadCSVFileType): AnyAction => {
  return {
    type: actions.EXPORT_FILE_BY_DATE,
    payload,
  }
}

export const exportAllocationCsvFile = (payload: LoadAllocationCSVFileType): AnyAction => {
  return {
    type: actions.EXPORT_ALLOCATION_FILE_BY_DATE,
    payload,
  }
}

export const exportProductivityCsvFile = (payload: LoadProductivityCSVFileType): AnyAction => {
  return {
    type: actions.EXPORT_PRODUCTIVITY_FILE_BY_DATE,
    payload,
  }
}

export const clearData = (): AnyAction => {
  return {
    type: actions.CLEAR_DATA,
  }
}

export const changeRoomStatus = (payload: ChangeStatusType): AnyAction => {
  return {
    type: actions.CHANGE_ROOM_STATUS,
    payload,
  }
}

export const changeRoomStatusWithData = (payload: ChangeStatusTypeWithData): AnyAction => {
  return {
    type: actions.CHANGE_ROOM_STATUS_WITH_DATA,
    payload,
  }
}

export const startRoomsSocketChannel = (id?: string): AnyAction => {
  return {
    type: actions.START_ROOMS_SOCKET_CHANNEL,
    id,
  }
}

export const stopRoomsSocketChannel = (): AnyAction => {
  return {
    type: actions.CLOSE_ROOMS_SOCKET_CHANNEL,
  }
}

export const updateRoomById = (payload: RoomType): AnyAction => {
  return {
    type: actions.UPDATE_ROOM_BY_ID,
    payload,
  }
}

export const updateRoomsBySocket = (rooms: RoomType[]): AnyAction => {
  return {
    type: actions.UPDATE_ROOMS_BY_SOCKET,
    rooms,
  }
}

export const closeCsvModal = (data: UserType): AnyAction => {
  return {
    type: actions.CLOSE_CSV_MODAL,
    data,
  }
}

export const saveWorkshiftOrderSaga = (
  data: { name: string; id: string; image?: string }[],
): AnyAction => {
  return {
    type: actions.SAVE_WORKSHIFT_ORDER,
    data,
  }
}

export default actions
