import { Action, Dispatch, AnyAction } from 'redux'
import axios from '@/config/axiosInterceptor'
import { AxiosResponse } from 'axios'
import { BASE_URLS, ENVIRONMENT_MODE } from '@/config/constants'
import { UserType } from '@/redux/user/types'
import store from '@/redux/store'
import { setUserInfo } from '@/redux/user/actions'
import { IRootState } from '@/redux/reducers'
import * as action_types from './constants'
import { Ilogin } from './types'

const baseURL = BASE_URLS[ENVIRONMENT_MODE].REACT_APP_API_TASK_URL



export const isLoggedIn = () => {
  return !!localStorage.getItem('@maitretoken')
}

export const loading = (data: boolean): AnyAction => {
  return {
    type: action_types.SET_LOADING,
    payload: { loading: data },
  }
}

export const getUserDetails = () => {
  return async (
    dispatch: Dispatch<Action<string>>,
  ): Promise<{
    type: string
    data: UserType
  }> => {
    const { data }: AxiosResponse<UserType> = await axios.get(`${baseURL}getUsersInfo`)
    return dispatch({ type: 'user/SET_USER_INFO', data })
  }
}

export const loginToken = (data: any): AnyAction => {
  return {
    type: action_types.LOGIN,
    data,
  }
}

// export const login = ({ email, password }: { email: string; password: string }) => {
//   const storeDispatch = store?.dispatch
//   storeDispatch(loading(true))
//   return async (
//     dispatch: Dispatch<Action<string>>,
//   ): Promise<{
//     type: string
//     data: Ilogin['token']['accessToken']
//   }> => {
//     const { data }: AxiosResponse<Ilogin> = await axios.post(`${baseURL}login`, { email, password })
//     await localStorage.setItem('@maitretoken', JSON.stringify(data.token))

//     storeDispatch(getUserDetails())
//     return dispatch({
//       type: action_types.LOGIN,
//       data: data.token.accessToken,
//     })
//   }
// }

export const logout = () => {
  const storeDispatch = store?.dispatch
  storeDispatch(loading(false))
  return async (
    dispatch: Dispatch<Action<string>>,
  ): Promise<{
    type: string
  }> => {
    const user: IRootState['user'] = store?.getState().user

    const { data }: AxiosResponse<Ilogin> = await axios.put(
      `${baseURL}resetToken/${user?.user?.id}`,
    )
    await localStorage.removeItem('@maitretoken')

    // storeDispatch(getUserDetails())
    dispatch({ type: 'user/SET_USER_INFO', data: null })
    return dispatch({
      type: action_types.LOGOUT,
      data: undefined,
    })
  }
}
