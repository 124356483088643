import { AnyAction } from 'redux'
import {
  CreateNoteType,
  DeleteRoomNoteType,
  EditRoomNoteType,
  GetRoomNotesType,
  NotesDocType,
} from './types'

const actions = {
  SET_STATE: 'notes/SET_STATE',
  CREATE_NOTE: 'notes/CREATE_NOTE',
  GET_ROOM_NOTES: 'notes/GET_ROOM_NOTES',
  EDIT_ROOM_NOTE: 'notes/EDIT_ROOM_NOTE',
  EDIT_ROOM_NOTE_SUCCESS: 'notes/EDIT_ROOM_NOTE_SUCCESS',
  DELETE_ROOM_NOTE: 'notes/DELETE_ROOM_NOTE',
  DELETE_ROOM_NOTE_SUCCESS: 'notes/DELETE_ROOM_NOTE_SUCCESS',
  SET_ROOM_NOTES: 'notes/SET_ROOM_NOTES',
  ADD_ROOM_NOTE: 'notes/ADD_ROOM_NOTE',
  START_NOTES_SOCKET_CHANNEL: 'notes/START_NOTES_SOCKET_CHANNEL',
  CLOSE_NOTES_SOCKET_CHANNEL: 'notes/CLOSE_NOTES_SOCKET_CHANNEL',
  CLEAR_ALL: 'notes/CLEAR_ALL',
}

export const loading = (data: boolean): AnyAction => {
  return {
    type: actions.SET_STATE,
    payload: { loading: data },
  }
}

export const setRoomNotes = (payload: {
  notes: NotesDocType[]
  hasNext: boolean
  lastId: string
}): AnyAction => {
  return {
    type: actions.SET_ROOM_NOTES,
    payload,
  }
}

export const setState = (payload: unknown): AnyAction => {
  return {
    type: actions.SET_STATE,
    payload,
  }
}

export const createNote = (payload: CreateNoteType): AnyAction => {
  console.log('action', payload)
  return {
    type: actions.CREATE_NOTE,
    payload,
  }
}

export const getRoomNotes = (payload: GetRoomNotesType): AnyAction => {
  return {
    type: actions.GET_ROOM_NOTES,
    payload,
  }
}

export const editRoomNote = (payload: EditRoomNoteType): AnyAction => {
  return {
    type: actions.EDIT_ROOM_NOTE,
    payload,
  }
}

export const editRoomNoteSuccess = (notes: NotesDocType[]): AnyAction => {
  return {
    type: actions.EDIT_ROOM_NOTE_SUCCESS,
    notes,
  }
}

export const deleteRoomNote = (payload: DeleteRoomNoteType): AnyAction => {
  return {
    type: actions.DELETE_ROOM_NOTE,
    payload,
  }
}

export const deleteRoomNoteSuccess = (note: string): AnyAction => {
  return {
    type: actions.DELETE_ROOM_NOTE_SUCCESS,
    note,
  }
}

export const addRoomNote = (notes: NotesDocType[]): AnyAction => {
  return {
    type: actions.ADD_ROOM_NOTE,
    notes,
  }
}

export const startNotesSocketChannel = (id: string): AnyAction => {
  return {
    type: actions.START_NOTES_SOCKET_CHANNEL,
    id,
  }
}

export const closeNotesSocketChannel = (): AnyAction => {
  return {
    type: actions.CLOSE_NOTES_SOCKET_CHANNEL,
  }
}

export const clearAll = (): AnyAction => {
  return {
    type: actions.CLEAR_ALL,
  }
}

export default actions
