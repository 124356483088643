import { FunctionComponent } from 'react'
import {
  // RoomIcon,
  // AllocationIcon,
  // TasksIcon,
  // ProjectIcon,
  // ChecklistIcon,
  // MeetingIcon,
  // UsersIcon,
  SvgIconType,
  // HotelsIcon,
  // SettingsIcon,
  // ReportIcon,
  // LocationIcon,
} from '@/components/Icons'
import constants from '@/config/constants'
import HelpCenterIcon from '@/components/Icons/HelpCenterIcon'
import { PermissionNames } from '@/redux/user/types'
import {
  AllocationsIcon,
  ChecklistIcon,
  DashboardIcon,
  LocationIcon,
  ProjectsIcon,
  ReportsIcon,
  RoomIcon,
  SettingIcon,
  TasksIcon,
  UserIcon,
  DeliveryServiceIcon,
} from '@/components/IconsCustom'

export interface NavigationItemType {
  name: string
  label: string
  path: string
  icon: FunctionComponent<SvgIconType>
  position: 'top' | 'bottom'
  permission?: string[]
  permissionName?: PermissionNames
}

const navigations: Array<NavigationItemType> = [
  {
    name: 'dashboard',
    label: 'Dashboard',
    path: '/dashboard',
    icon: DashboardIcon,
    // icon: MeetingIcon,
    permissionName: 'view_location_dashboard',
    position: 'top',
    permission: [],
  },
  {
    name: 'rooms',
    label: 'Rooms',
    path: '/rooms',
    icon: RoomIcon,
    position: 'top',
    permissionName: 'view_room_status_board',
    permission: [],
  },
  {
    name: 'allocations',
    label: 'Allocations',
    path: '/allocations',
    icon: AllocationsIcon,
    position: 'top',
    permissionName: 'manage_room_allocation',
    permission: [],
  },
  {
    name: 'tasks',
    label: 'Tasks',
    path: '/tasks',
    icon: TasksIcon,
    position: 'top',
    permissionName: 'view_tasks',
    permission: [],
  },
  {
    name: 'projects',
    label: 'Projects',
    path: '/projects',
    icon: ProjectsIcon,
    permissionName: 'view_projects',
    position: 'top',
    permission: [],
  },
  {
    name: 'checklist',
    label: 'Checklists & SOP',
    path: '/daily-checklist',
    icon: ChecklistIcon,
    permissionName: 'view_checklist_and_sop',
    position: 'top',
    permission: [],
  },
  // {
  //   name: 'reports',
  //   label: 'Reports',
  //   path: '/reports',
  //   icon: ReportsIcon,
  //   position: 'top',
  //   permissionName: 'access_reports',
  //   permission: [
  //     constants.ROLE_MANAGER,
  //     constants.ROLE_ENGINEERING_MANAGER,
  //     constants.ROLE_HOUSEKEEPING_MANAGER,
  //     constants.ROLE_STEWARDING_MANAGER,
  //     constants.ROLE_CLEANING_SUPERVISOR,
  //     constants.ROLE_ENGINEERING_SUPERVISOR,
  //     constants.ROLE_HOUSEKEEPING_INSPECTOR,
  //     constants.ROLE_HOUSEKEEPING_SUPERVISOR,
  //     constants.ROLE_SECURITY_MANAGER,
  //     constants.ROLE_SECURITY_SUPERVISOR,
  //     constants.ROLE_STEWARDING_SUPERVISOR,
  //     constants.ROLE_FRONT_DESK_MANAGER,
  //     constants.ROLE_FOOD_BEVERAGE_MANAGER,
  //     constants.ROLE_CONCIERGE_MANAGER,
  //   ],
  // },

  {
    name: 'adminreports',
    label: 'Reports',
    path: '/reports',
    icon: DashboardIcon,
    position: 'top',
    permissionName: 'access_reports',
    permission: [],
  },
  // {
  //   name: 'UsersLocation',
  //   label: 'Role Privileges',
  //   path: '/role-privileges',
  //   icon: ReportIcon,
  //   position: 'top',
  //   permission: [
  //     constants.ROLE_MANAGER,
  //     constants.ROLE_ENGINEERING_MANAGER,
  //     constants.ROLE_HOUSEKEEPING_MANAGER,
  //     constants.ROLE_STEWARDING_MANAGER,
  //     constants.ROLE_CLEANING_SUPERVISOR,
  //     constants.ROLE_ENGINEERING_SUPERVISOR,
  //     constants.ROLE_HOUSEKEEPING_INSPECTOR,
  //     constants.ROLE_HOUSEKEEPING_SUPERVISOR,
  //     constants.ROLE_SECURITY_MANAGER,
  //     constants.ROLE_SECURITY_SUPERVISOR,
  //     constants.ROLE_STEWARDING_SUPERVISOR,
  //     constants.ROLE_FRONT_DESK_MANAGER,
  //     constants.ROLE_FOOD_BEVERAGE_MANAGER,
  //     constants.ROLE_CONCIERGE_MANAGER,
  //   ],
  // },
  // {
  //   name: 'helpcenter',
  //   label: 'helpcenter',
  //   path: '/help-center',
  //   icon: HelpCenterIcon,
  //   position: 'bottom',
  //   permission: [
  //     constants.ROLE_MANAGER,
  //     constants.ROLE_ENGINEERING_MANAGER,
  //     constants.ROLE_HOUSEKEEPING_MANAGER,
  //     constants.ROLE_STEWARDING_MANAGER,
  //     constants.ROLE_CLEANING_SUPERVISOR,
  //     constants.ROLE_ENGINEERING_SUPERVISOR,
  //     constants.ROLE_HOUSEKEEPING_INSPECTOR,
  //     constants.ROLE_HOUSEKEEPING_SUPERVISOR,
  //     constants.ROLE_SECURITY_MANAGER,
  //     constants.ROLE_SECURITY_SUPERVISOR,
  //     constants.ROLE_STEWARDING_SUPERVISOR,
  //     constants.ROLE_FRONT_DESK_MANAGER,
  //     constants.ROLE_FOOD_BEVERAGE_MANAGER,
  //     constants.ROLE_CONCIERGE_MANAGER,
  //   ],
  // },

  // {
  //   name: 'hotels',
  //   label: 'Locations',
  //   path: '/admin/hotels',
  //   icon: LocationIcon,
  //   // icon: HotelsIcon,
  //   position: 'top',
  //   permission: [constants.ROLE_ADMIN],
  // },
  {
    name: 'adminLocations',
    label: 'Locations',
    path: '/admin/locations',
    icon: LocationIcon,
    // icon: HotelsIcon,
    position: 'top',
    permission: [constants.ROLE_ADMIN],
  },
  {
    name: 'users',
    label: 'Users',
    path: '/admin/users',
    icon: UserIcon,
    position: 'top',
    permission: [constants.ROLE_ADMIN],
  },
  {
    name: 'AdminLocations',
    label: 'Delivery Services',
    path: '/admin/delivery-service',
    icon: DeliveryServiceIcon,
    position: 'top',
    permission: [constants.ROLE_ADMIN],
  },
  // {
  //   name: 'users',
  //   label: 'Users',
  //   path: '/manager/users',
  //   icon: UserIcon,
  //   position: 'top',
  //   permission: [],
  // },
  {
    name: 'settings',
    label: 'Settings',
    path: '/settings',
    icon: SettingIcon,
    permissionName: 'manage_location_settings',
    position: 'top',
    permission: [],
  },
]
export default navigations
