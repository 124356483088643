import { Action, Dispatch } from 'redux'
import axios from '@/config/axiosInterceptor'
import { AxiosResponse } from 'axios'
import { BASE_URLS, ENVIRONMENT_MODE } from '@/config/constants'

const baseURL = BASE_URLS[ENVIRONMENT_MODE].REACT_APP_API_TASK_URL

export const createRequest = (payload: any) => {
  return axios.post(`${baseURL}updateItems`, payload)
}

export const deleteRequest = (id: string) => {
  return axios.delete(`${baseURL}deleteItem/${id}/true`)
}

export const createCategory = (payload: any) => {
  return axios.post(`${baseURL}updateRequestCategory`, payload)
}

export const deleteCategory = (id: string) => {
  return axios.delete(`${baseURL}deleteRequestCategory/${id}/true`)
}

export const updateTaskStatus = (id: string, data: any) => {
  return axios.patch(`${baseURL}updateStatus/${id}`, data)
}

export const updateRequestCategoryIndex = (payload: any) => {
  return axios.post(`${baseURL}updateRequestCategoryIndex`, payload)
}

export const createDeliverySchedule = (payload: any) => {
  return axios.post(`${baseURL}createDeliverySchedule`, payload)
}

export const updatePermission = ({
  id,
  access_level,
  is_checked,
}: {
  id: string
  access_level: number
  is_checked: boolean
}): Promise<AxiosResponse<void>> => {
  return axios.put(
    `${baseURL}updateRolePermission/${id}`,
    JSON.stringify({ is_checked, access_level }),
  )
}

export const deActiveLocation = (hotelID: string, type: string) => {
  return axios.put(`${baseURL}hotels/${hotelID}/${type}`)
}
