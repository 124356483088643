import { lazy } from 'react'

const home = lazy(() => import('@/views/Home'))
const login = lazy(() => import('@/views/Login'))
const newLogin = lazy(() => import('@/views/NewLogin'))
const forgotPassword = lazy(() => import('@/views/ForgotPassword'))
const passwordReset = lazy(() => import('@/views/PasswordReset'))
const UpdatePassword = lazy(() => import('@/views/UpdatePassword'))
const account = lazy(() => import('@/views/Account'))
const meeting = lazy(() => import('@/views/MorningMeeting'))
const allocations = lazy(() => import('@/views/Allocations'))
const rooms = lazy(() => import('@/views/Rooms'))
const adminUsers = lazy(() => import('@/views/Users'))
const userDetails = lazy(() => import('@/views/UserDetails'))
const addUser = lazy(() => import('@/views/AddUser'))
const adminLocations = lazy(() => import('@/views/AdminLocations'))
const deliveryService = lazy(() => import('@/views/DeliveryService'))
const CreateLocation = lazy(() => import('@/views/AdminLocations/CreateLocation'))
const UsersLocation = lazy(() => import('@/views/UsersLocation'))
const adminHotels = lazy(() => import('@/views/Hotels'))
const addHotel = lazy(() => import('@/views/AddHotel'))
const editHotel = lazy(() => import('@/views/EditHotel'))
const tasks = lazy(() => import('@/views/Tasks'))
const newTask = lazy(() => import('@/views/Tasks/NewTask'))
const editTask = lazy(() => import('@/views/Tasks/EditTask'))
const taskDetail = lazy(() => import('@/views/Tasks/TaskDetail'))
const uiKit = lazy(() => import('@/views/ui-kit'))
const projects = lazy(() => import('@/views/Projects'))
const newProjects = lazy(() => import('@/views/Projects/NewProject'))
const projectDetail = lazy(() => import('@/views/Projects/ProjectDetails'))
const editProject = lazy(() => import('@/views/Projects/EditProject'))
const settings = lazy(() => import('@/views/Settings'))
const dailyChecklist = lazy(() => import('@/views/DailyChecklists'))
const dashboard = lazy(() => import('@/views/Dashboard'))
const createShift = lazy(() => import('@/views/Dashboard/components/Sidebar/CreateShift'))
const helpCenter = lazy(() => import('@/views/HelpCenter'))
const reports = lazy(() => import('@/views/Report'))
const noAccess = lazy(() => import('@/views/NoAccess'))
const staff = lazy(() => import('@/views/StaffScreen'))
const NotFound = lazy(() => import('@/views/404'))
const adminReports = lazy(() => import('@/views/Reports'))
const productivityReport = lazy(() => import('@/views/Reports/components/ProductivityReport'))
const cleaningReport = lazy(() => import('@/views/Reports/components/CleaningReport'))
const requestReport = lazy(() => import('@/views/Reports/components/RequestReport'))
const cleaningRating = lazy(() => import('@/views/Reports/components/CleaningRating'))
const averageRequest = lazy(() => import('@/views/Reports/components/averageRequestReport'))

const routes = [
  {
    component: home,
    path: '/',
    exact: true,
  },
  {
    component: newLogin,
    path: '/auth',
    exact: true,
    fullLayout: true,
  },
  {
    component: newLogin,
    path: '/login',
    exact: true,
    fullLayout: true,
  },
  {
    component: passwordReset,
    path: '/password-reset/:otp/:type',
    exact: true,
    fullLayout: true,
  },
  {
    component: UpdatePassword,
    path: '/update-password',
    exact: true,
    fullLayout: true,
  },
  {
    component: forgotPassword,
    path: '/forgot-password',
    exact: true,
    fullLayout: true,
  },
  {
    component: account,
    path: '/account',
    exact: true,
  },
  {
    component: rooms,
    path: '/rooms',
    exact: true,
  },
  {
    component: meeting,
    path: '/morning-meeting',
    exact: true,
  },
  {
    component: allocations,
    path: '/allocations',
    exact: true,
  },
  {
    component: adminUsers,
    path: '/admin/users',
    exact: true,
  },
  {
    component: adminUsers,
    path: '/manager/users',
    exact: true,
  },
  {
    component: adminLocations,
    path: '/admin/locations',
    exact: true,
  },
  {
    component: deliveryService,
    path: '/admin/delivery-service',
    exact: true,
  },
  {
    component: CreateLocation,
    path: '/admin/create-location',
    exact: true,
  },
  {
    component: CreateLocation,
    path: '/manager/create-location',
    exact: true,
  },
  {
    component: adminLocations,
    path: '/manager/locations',
    exact: true,
  },
  {
    component: UsersLocation,
    path: '/role-privileges',
    exact: true,
  },
  {
    component: addUser,
    path: '/admin/users/add',
    exact: true,
  },
  {
    component: userDetails,
    path: '/admin/users/:id',
    exact: true,
  },
  {
    component: adminHotels,
    path: '/admin/hotels',
    exact: true,
  },
  {
    component: addHotel,
    path: '/admin/hotels/add',
    exact: true,
  },
  {
    component: editHotel,
    path: '/admin/hotels/:id',
    exact: true,
  },
  {
    component: tasks,
    path: '/tasks',
    exact: true,
  },
  {
    component: newTask,
    path: '/new-task',
    exact: true,
  },
  {
    component: taskDetail,
    path: '/task-detail/:id',
    exact: true,
  },
  {
    component: editTask,
    path: '/edit-task/:id',
    exact: true,
  },
  {
    component: uiKit,
    path: '/ui-kit',
    exact: true,
  },
  {
    component: projects,
    path: '/projects',
    exact: true,
  },
  {
    component: newProjects,
    path: '/new-projects',
    exact: true,
  },
  {
    component: projectDetail,
    path: '/project-detail/:id',
    exact: true,
  },
  {
    component: editProject,
    path: '/edit-project/:id',
    exact: true,
  },
  {
    component: settings,
    path: '/settings',
    exact: true,
  },
  {
    component: settings,
    path: '/settings/:id',
    exact: true,
  },
  {
    component: dailyChecklist,
    path: '/daily-checklist',
    exact: true,
  },
  {
    component: dashboard,
    path: '/dashboard',
    exact: true,
  },

  {
    component: createShift,
    path: '/create-shift',
    exact: true,
  },
  {
    component: helpCenter,
    path: '/help-center',
    exact: true,
  },
  // {
  //   component: reports,
  //   path: '/reports',
  //   exact: true,
  // },
  { component: noAccess, path: '/noAccess', exact: true },
  { component: staff, path: '/staff', exact: true },
  { component: NotFound, path: '/404', exact: true },
  {
    component: adminReports,
    path: '/reports',
    exact: true,
  },
  {
    component: productivityReport,
    path: '/reports/productivity-report',
    exact: true,
  },
  {
    component: cleaningReport,
    path: '/reports/cleaning-report',
    exact: true,
  },
  {
    component: requestReport,
    path: '/reports/request-report',
    exact: true,
  },
  {
    component: cleaningRating,
    path: '/reports/cleaning-rating',
    exact: true,
  },
  {
    component: averageRequest,
    path: '/reports/average-request-time',
    exact: true,
  },
]

export default routes
