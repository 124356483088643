import { AnyAction } from 'redux'
import {
  AllocationRoomsType,
  TurnDownAllocationsType,
  DragHistoryType,
  DragType,
} from '@/redux/turndownAllocations/types'

const actions = {
  GET_ROOMS_WITH_ATTENDANTS_REQUEST_TURNDOWN:
    'turndownAllocations/GET_ROOMS_WITH_ATTENDANTS_REQUEST_TURNDOWN',
  SET_STATE_TURNDOWN: 'turndownAllocations/SET_STATE_TURNDOWN',
  SET_FILTERS_TURNDOWN: 'turndownAllocations/SET_FILTERS_TURNDOWN',
  DRAG_ELEMENT_TURNDOWN: 'turndownAllocations/DRAG_ELEMENT_TURNDOWN',
  CLEAR_ALLOCATED_TURNDOWN: 'turndownAllocations/CLEAR_ALLOCATED_TURNDOWN',
  UPDATE_STATE_TURNDOWN: 'turndownAllocations/UPDATE_STATE_TURNDOWN',
  REVERT_CHANGES_TURNDOWN: 'turndownAllocations/REVERT_CHANGES_TURNDOWN',
  GET_AUTO_ALLOCATE_TURNDOWN: 'turndownAllocations/GET_AUTO_ALLOCATE_TURNDOWN',
  GET_MULTI_ALLOCATE_TURNDOWN: 'turndownAllocations/GET_MULTI_ALLOCATE_TURNDOWN',
  SET_AUTO_ALLOCATE_TURNDOWN: 'turndownAllocations/SET_AUTO_ALLOCATE_TURNDOWN',
  SAVE_CHANGES_TURNDOWN: 'turndownAllocations/SAVE_CHANGES_TURNDOWN',
  SKIP_AND_SEND_TURNDOWN: 'turndownAllocations/SKIP_AND_SEND_TURNDOWN',
  CLEAR_ALL_TURNDOWN: 'turndownAllocations/CLEAR_ALL_TURNDOWN',
  START_ALLOCATION_SOCKET_CHANNEL_TURNDOWN:
    'turndownAllocations/START_ALLOCATION_SOCKET_CHANNEL_TURNDOWN',
  CLOSE_ALLOCATION_SOCKET_CHANNEL_TURNDOWN:
    'turndownAllocations/CLOSE_ALLOCATION_SOCKET_CHANNEL_TURNDOWN',
}

export const startAllocatonSocketChannel = (id?: string): AnyAction => {
  return {
    type: actions.START_ALLOCATION_SOCKET_CHANNEL_TURNDOWN,
    id,
  }
}

export const stopAllocatonSocketChannel = (): AnyAction => {
  return {
    type: actions.CLOSE_ALLOCATION_SOCKET_CHANNEL_TURNDOWN,
  }
}

export const getRoomsWithAttendants = (hotelID: string, date: string, type?: string): AnyAction => {
  return {
    type: actions.GET_ROOMS_WITH_ATTENDANTS_REQUEST_TURNDOWN,
    payload: { hotelID, date, type },
  }
}

export const dragElement = (data: DragType): AnyAction => {
  return {
    type: actions.DRAG_ELEMENT_TURNDOWN,
    payload: data,
  }
}

export const clearAllocated = (): AnyAction => {
  return {
    type: actions.CLEAR_ALLOCATED_TURNDOWN,
  }
}

export const clearAllocatedTurndown = (): AnyAction => {
  return {
    type: actions.CLEAR_ALLOCATED_TURNDOWN,
  }
}

export const updateState = (data: any): AnyAction => {
  return {
    type: actions.UPDATE_STATE_TURNDOWN,
    payload: data,
  }
}

export const revertChanges = (): AnyAction => {
  return {
    type: actions.REVERT_CHANGES_TURNDOWN,
  }
}

export const setFilters = (filters: { [k in string]: any[] | unknown }): AnyAction => {
  return {
    type: actions.SET_FILTERS_TURNDOWN,
    payload: filters,
  }
}

export const setRoomsWithAttendantsSuccess = (payload: TurnDownAllocationsType): AnyAction => {
  return {
    type: actions.SET_STATE_TURNDOWN,
    payload,
  }
}

export const setRoomsAttendantsSuccess = (payload: AllocationRoomsType): AnyAction => {
  return {
    type: actions.SET_STATE_TURNDOWN,
    payload: { ...payload, history: {} },
  }
}

export const getAutoAllocate = (hotelID: string, type: string, roomIds: string[]): AnyAction => {
  return {
    type: actions.GET_AUTO_ALLOCATE_TURNDOWN,
    payload: { hotelID, type, roomIds },
  }
}

export const setAutoAllocate = (payload: {
  turndownAllocated: Record<string, string[]>
  history: DragHistoryType
}): AnyAction => {
  return {
    type: actions.SET_AUTO_ALLOCATE_TURNDOWN,
    payload,
  }
}

export const saveChanges = (
  HotelID: string,
  date: string,
  statusType: string,
  { afterSubmit }: { afterSubmit?: () => void },
): AnyAction => {
  return {
    type: actions.SAVE_CHANGES_TURNDOWN,
    data: { HotelID, date, statusType, afterSubmit },
  }
}

export const skipAndSend = (): AnyAction => {
  return {
    type: actions.SKIP_AND_SEND_TURNDOWN,
  }
}

export const saveSkipped = (turndownAllocated: Record<string, string[]>): AnyAction => {
  return {
    type: actions.SET_STATE_TURNDOWN,
    payload: { turndownAllocated },
  }
}

export const loading = (data: boolean): AnyAction => {
  return {
    type: actions.SET_STATE_TURNDOWN,
    payload: { loading: data },
  }
}

export const updating = (data: boolean): AnyAction => {
  return {
    type: actions.SET_STATE_TURNDOWN,
    payload: { updating: data },
  }
}

export const clearAll = (): AnyAction => {
  return {
    type: actions.CLEAR_ALL_TURNDOWN,
  }
}

export default actions
