import Icon from '@ant-design/icons'

const DropDownSvg = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M10.483 13.3069C10.2163 13.5644 9.78375 13.5644 9.51699 13.3069L5.70144 9.6252C5.27112 9.20997 5.57589 8.5 6.18445 8.5H13.8155C14.4241 8.5 14.7289 9.20997 14.2986 9.6252L10.483 13.3069Z"
      fill="#16181D"
    />
  </svg>
)

const ArrowIconNew = (props: any) => <Icon component={DropDownSvg} {...props} />

export default ArrowIconNew
