import * as action_types from './constants'

const initialState = {
  notesData: [],
}

export default (state = initialState, action: any) => {
  const { type, data } = action
  switch (type) {
    case action_types.GET_ROOM_NOTES:
      return { ...state, notesData: data }

    default:
      return state
  }
}
