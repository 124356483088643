import * as action_types from './constants'
import { UserList, HotelList, ItemList } from './types'

export interface ReportsStateType {
  userList: UserList[]
  hotelList: HotelList[]
  itemList: ItemList[]
}

const initialState = {
  userList: [],
  hotelList: [],
  itemList: [],
}

export default (state = initialState, action: any) => {
  const { type, data } = action
  switch (type) {
    case action_types.USERLIST:
      return { ...state, userList: data }

    case action_types.HOTEL_LIST:
      return { ...state, hotelList: data }

        case action_types.ITEMLIST:
      return { ...state, itemList: data }

    default:
      return state
  }
}
