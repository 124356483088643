// import OAuth from '@DevimaSolutions/o-auth'
import axios from '@/config/axiosInterceptor'
import { RoomResponseType, RoomsResponseType, RoomType } from '@/redux/rooms/types'
import { BASE_URLS, ENVIRONMENT_MODE } from '@/config/constants'
import { BodyChanges } from '@/redux/turndownAllocations/types'
import { AxiosResponse } from 'axios'

const baseURL = BASE_URLS[ENVIRONMENT_MODE].REACT_APP_API_TASK_URL

export const getRoomsWithAttendants = async (hotelID: string, date: string, type: string) => {
  const { data } = await axios.get(`${baseURL}getRoomsForAllocation/${hotelID}/${date}/${type}`)
  const newData: RoomResponseType = data
  return {
    totalFloors: newData.total_count,
    rooms: newData.floors?.reduce<Record<string, RoomType>>((acc, floor) => {
      return {
        ...acc,
        ...floor.rooms.reduce<Record<string, RoomType>>((carry, room) => {
          carry[room.id] = {
            ...room,
            floor: floor.floor_number,
          }
          return carry
        }, {}),
      }
    }, {}),
  }
}

export const getAutoAllocationService = async (
  hotelID: string,
  type: string,
  roomIds: string[],
): Promise<Record<string, string[]>[]> => {
  const body = {
    hotel_id: hotelID,
    allocation_type: type,
    room_ids: roomIds,
  }
  const { data } = await axios.post(`${baseURL}setAttendantAllocation`, body)
  return data
}

export const saveChanges = async (type: string, body: BodyChanges): Promise<unknown> => {
  const { data } = await axios.post(`${baseURL}saveAllocations`, body)
  return data
}
