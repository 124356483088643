import * as action_types from './constants'

const initialState = {
  hotelDeliveryServices: [],
  staffhotels: [],
}

export default (state = initialState, action: any) => {
  const { type, data } = action
  switch (type) {
    case action_types.USERS_HOTEL_DELIVERY:
      return { ...state, hotelDeliveryServices: data }

    case action_types.USERS_HOTEL_STAFF:
      return { ...state, staffhotels: data }

    default:
      return state
  }
}
