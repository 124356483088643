export const ALLOCATIONS = 'ALLOCATIONS'
export const REQ_DATA = 'REQ_DATA'
export const TASKS_LIST = 'TASKS_LIST'
export const FILTER_DATA = 'FILTER_DATA'
export const SET_FILTERS_OPTIONS = 'SET_FILTERS_OPTIONS'
export const TASKS_DATA = 'TASKS_DATA'
export const ROOMS_CLEANING = 'ROOMS_CLEANING'
export const TASK_FORM_DATA_ASSIGNEES = 'TASK_FORM_DATA_ASSIGNEES'
export const GET_ROOM_NOTES = 'GET_ROOM_NOTES'
// export const CREATE_ROOM_NOTES = 'CREATE_ROOM_NOTES'
