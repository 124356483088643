import * as action_types from './constants'

const initialState = {
  adminDataList: [],
  reqData: [],
  roomsCleaning: [],
  taskListData: [],
  filtersData: [],
  tasksData: [],
  assigneesList: [],
  notesData: [],
  filter: {
    assignees: undefined,
    departments: undefined,
    types: undefined,
    locations: undefined,
    start_time: undefined,
  },
}

export default (state = initialState, action: any) => {
  const { type, data } = action
  switch (type) {
    case action_types.ALLOCATIONS:
      return { ...state, adminDataList: data }

    case action_types.REQ_DATA:
      return { ...state, reqData: data }

    case action_types.TASKS_LIST:
      return { ...state, taskListData: data }

    case action_types.FILTER_DATA:
      return { ...state, filtersData: data }

    case action_types.TASKS_DATA:
      return { ...state, tasksData: data }

    case action_types.SET_FILTERS_OPTIONS:
      return { ...state, filter: { ...state.filter, ...action.payload.filter } }

    case action_types.ROOMS_CLEANING:
      return { ...state, roomsCleaning: data }

    case action_types.TASK_FORM_DATA_ASSIGNEES:
      return { ...state, assigneesList: data }

    case action_types.GET_ROOM_NOTES:
      return { ...state, notesData: data }

    // case action_types.CREATE_ROOM_NOTES:
    //   return { ...state, notesData: data }

    default:
      return state
  }
}
